<template>
  <div id="orderToday">
    <a-spin :spinning="spinLoading">
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_003') }}: </span>
          <a-input
            v-model:value="searchQuery.downOrderNum"
            allow-clear
            @pressEnter="loadOrders(1)"
            @blur="loadOrders(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_004') }}: </span>
          <a-select
            v-model:value="searchQuery.requestType"
            allow-clear
            @change="loadOrders(1)"
          >
            <a-select-option
              v-for="item in requestTypeList"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_005') }}: </span>
          <a-select
            v-model:value="searchQuery.tradeState"
            allow-clear
            @change="loadOrders(1)"
          >
            <a-select-option
              v-for="item in tradeStateList"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_006') }}: </span>
          <a-select
            v-model:value="searchQuery.channelCode"
            allow-clear
            @change="loadOrders(1)"
          >
            <a-select-option
              v-for="item in channelList"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_007') }}: </span>
          <a-select
            v-model:value="searchQuery.searchType"
            allow-clear
            @change="loadOrders(1)"
          >
            <a-select-option
              v-for="item in searchTypeList"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_014') }}: </span>
          <a-time-picker
            v-model:value="searchQuery.queryStartTime"
            format="HH:mm"
            @change="loadOrders(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receipt_015') }}: </span>
          <a-time-picker
            v-model:value="searchQuery.queryEndTime"
            format="HH:mm"
            @change="loadOrders(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="orders"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-button
                shape="round"
                size="small"
                class="btn-primary-empty-0"
                @click="showDetail(record)"
              >{{ $t('pages_receipt_035') }}</a-button>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </a-spin>
    <order-detail
      v-model:visible="visible"
      :order-info="orderInfo"
    />
  </div>
</template>

<script>
import TablePage from '@/components/table-page'
import OrderDetail from './order-detail'
import { reactive, toRefs } from 'vue'
import { _order } from '@/api'
import { parseMoney } from '@/util'
import i18n from '@/locale'
import moment from 'moment'
export default {
  name: 'OrderToday',
  components: {
    'table-page': TablePage,
    'order-detail': OrderDetail
  },
  props: {
    requestTypeList: {
      type: Array,
      default: () => { return [] }
    },
    tradeStateList: {
      type: Array,
      default: () => { return [] }
    },
    channelList: {
      type: Array,
      default: () => { return [] }
    },
    searchTypeList: {
      type: Array,
      default: () => { return [] }
    },
    settlementList: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props) {
    const state = reactive({
      loading: true,
      visible: false,
      spinLoading: false,
      total: 0,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        downOrderNum: null,
        tradeState: null,
        requestType: null,
        channelCode: null,
        queryStartTime: null,
        queryEndTime: null,
        searchType: '2'
      },
      orderInfo: {},
      orders: [],
      columns: [
        { key: 'downOrderNum', dataIndex: 'downOrderNum', title: i18n.global.t('pages_receipt_003'), width: 220 },
        { key: 'upOrderNum', dataIndex: 'upOrderNum', title: i18n.global.t('pages_receipt_009'), width: 180 },
        { key: 'amt', dataIndex: 'amtText', title: i18n.global.t('pages_receipt_010'), width: 180 },
        { key: 'requestType', dataIndex: 'requestTypeText', title: i18n.global.t('pages_receipt_004'), width: 150 },
        { key: 'channelCode', dataIndex: 'channelCode', title: i18n.global.t('pages_receipt_011'), width: 150 },
        { key: 'leaveMessage', dataIndex: 'leaveMessage', title: i18n.global.t('pages_receipt_012'), width: 200, ellipsis: true },
        { key: 'createTime', dataIndex: 'createTimeAt', title: i18n.global.t('pages_receipt_008'), width: 180 },
        { key: 'settlementTime', dataIndex: 'settlementTimeAt', title: i18n.global.t('pages_receipt_013'), width: 180 },
        { key: 'tradeState', dataIndex: 'tradeStateText', title: i18n.global.t('pages_receipt_016'), fixed: 'right', width: 100 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: 120 }
      ]
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadOrders()
    }

    const showDetail = async (record) => {
      state.spinLoading = true
      const res = await _order.getOrderDetail({
        orderNum: record.downOrderNum,
        type: '0'
      })
      if (res.data.succ) {
        const { data } = res.data
        state.visible = true
        const settlement = props.settlementList.find(it => data.settlement === it.code)
        state.orderInfo = Object.assign({}, record, {
          amtText: `${parseMoney(data.amt / 100)} ${data.amtCurrency || ''}`,
          settlementText: settlement ? settlement.name : '-',
          orderFeeText: data.orderFee ? `${parseMoney(data.orderFee / 100)} ${data.receiveCurrency || ''}` : '-',
          refundableAmountText: `${parseMoney(data.refundableAmount / 100)} ${data.currency || ''}`
        })
      }
      state.spinLoading = false
    }

    const loadOrders = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const query = Object.assign({}, state.searchQuery, {
        page: state.searchQuery.pageNum,
        rows: state.searchQuery.pageSize,
        queryStartTime: state.searchQuery.queryStartTime ? new Date(state.searchQuery.queryStartTime).getTime() : null,
        queryEndTime: state.searchQuery.queryEndTime ? new Date(state.searchQuery.queryEndTime).getTime() : null
      })
      const res = await _order.getTodayOrders(query)
      if (res.data.succ) {
        const list = res.data.data.list || []
        state.orders = list.map(item => {
          const tradeState = props.tradeStateList.find(it => item.tradeState === it.code)
          const requestType = props.requestTypeList.find(it => item.requestType === it.code)
          return Object.assign({}, item, {
            tradeStateText: tradeState ? tradeState.name : '-',
            requestTypeText: requestType ? requestType.name : '-',
            amtText: `${parseMoney(item.amt / 100)} ${item.amtCurrency || item.currency || ''}`,
            receiveAmtText: `${parseMoney(item.receiveAmount / 100)} ${item.receiveCurrency || ''}`,
            createTimeAt: item.createTime ? moment(item.createTime).format('YYYY-MM-DD HH:mm') : '-',
            settlementTimeAt: item.settlementTime ? moment(item.settlementTime).format('YYYY-MM-DD HH:mm') : '-'
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    const init = () => {
      loadOrders()
    }

    init()

    return {
      showDetail,
      updatePage,
      loadOrders,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#orderToday {
  .table-part {
    margin-top: 12px;
  }
}
</style>

