<template>
  <a-modal
    width="60%"
    wrap-class-name="order-detail"
    :title="$t('pages_receipt_036')"
    :visible="visible"
    :mask-closable="false"
    :closable="false"
  >
    <div class="order-info">
      <div class="info-body">
        <div class="item">{{ $t('pages_receipt_037') }}: <span>{{ orderInfo.merchantNo }}</span></div>
        <div class="item">{{ $t('pages_receipt_038') }}: <span>{{ orderInfo.merchantName }}</span></div>
        <div class="item">{{ $t('pages_receipt_039') }}: <span>{{ orderInfo.downOrderNum }}</span></div>
        <div class="item">{{ $t('pages_receipt_040') }}: <span>{{ orderInfo.orderNum }}</span></div>
        <div v-if="orderInfo.originalOrderNum" class="item">{{ $t('pages_receipt_049') }}: <span>{{ orderInfo.originalOrderNum }}</span></div>
        <div class="item">{{ $t('pages_receipt_041') }}: <span>{{ orderInfo.amtText }}</span></div>
        <div class="item">{{ $t('pages_receipt_042') }}: <span>{{ orderInfo.orderFeeText }}</span></div>
        <div v-if="orderInfo.refundableAmount" class="item">{{ $t('pages_receipt_050') }}: <span>{{ orderInfo.refundableAmountText }}</span></div>
        <div v-if="orderInfo.receiveAmount" class="item">{{ $t('pages_receipt_043') }}: <span>{{ orderInfo.receiveAmtText }}</span></div>
        <div class="item">{{ $t('pages_receipt_044') }}: <span>{{ orderInfo.requestTypeText }}</span></div>
        <div class="item">{{ $t('pages_receipt_011') }}: <span>{{ orderInfo.channelCode }}</span></div>
        <div class="item">{{ $t('pages_receipt_045') }}: <span>{{ orderInfo.attach }}</span></div>
        <div class="item">{{ $t('pages_receipt_046') }}: <span>{{ orderInfo.tradeStateText }}</span></div>
        <div class="item">{{ $t('pages_receipt_047') }}: <span>{{ orderInfo.settlementText }}</span></div>
        <div class="item">{{ $t('pages_receipt_008') }}: <span>{{ orderInfo.createTimeAt }}</span></div>
        <div class="item">{{ $t('pages_receipt_048') }}: <span>{{ orderInfo.settlementTimeAt }}</span></div>
      </div>
    </div>
    <template #footer>
      <a-button type="primary" @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: 'Orderdetail',
  props: {
    visible: {
      type: Boolean,
      default: () => { return true }
    },
    orderInfo: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style lang="less">
.order-detail {
  .order-info {
    margin: -5px 0;
    .info-body {
      display: flex;
      flex-wrap: wrap;
      padding: 18px 0;
      .item {
        flex: 0 0 33.33%;
        margin-bottom: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 24px;
      }
    }
  }
}
</style>
