<template>
  <div id="receiptOrders">
    <header-nav :content="$t('pages_router_049')" />
    <div class="common-card-body">
      <a-tabs
        v-model:activeKey="labName"
        class="order-tabs"
      >
        <template #rightExtra>
          <a-button
            v-if="labName === 'history'"
            type="primary"
            :loading="downLoading"
            @click="exportReceipts"
          >
            <template #icon><download-outlined /></template>
            {{ downLoading ? $t('common_text_023') : $t('pages_receive_161') }}
          </a-button>
        </template>
        <a-tab-pane key="today" :tab="$t('pages_receipt_001')">
          <today
            ref="todayRef"
            :request-type-list="requestTypeList"
            :trade-state-list="tradeStateList"
            :channel-list="channelList"
            :search-type-list="searchTypeList"
            :settlement-list="settlementList"
          />
        </a-tab-pane>
        <a-tab-pane key="history" :tab="$t('pages_receipt_002')">
          <history
            ref="historyRef"
            :request-type-list="requestTypeList"
            :trade-state-list="tradeStateList"
            :channel-list="channelList"
            :search-type-list="searchTypeList"
            :settlement-list="settlementList"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/header-nav'
import Today from './components/today'
import History from './components/history'
import { ref, reactive, toRefs } from 'vue'
import i18n from '@/locale'
export default {
  name: 'ReceiptOrders',
  components: {
    'today': Today,
    'history': History,
    'header-nav': HeaderNav
  },
  setup() {
    const todayRef = ref(null)
    const historyRef = ref(null)

    const state = reactive({
      labName: 'today',
      downLoading: false,
      requestTypeList: [
        { code: 1, name: i18n.global.t('pages_receipt_017') },
        { code: 5, name: i18n.global.t('pages_receipt_018') }
      ],
      tradeStateList: [
        { code: 1, name: i18n.global.t('pages_receipt_019') },
        { code: 3, name: i18n.global.t('pages_receipt_020') },
        { code: 4, name: i18n.global.t('pages_receipt_021') },
        { code: 6, name: i18n.global.t('pages_receipt_022') },
        { code: 7, name: i18n.global.t('pages_receipt_023') },
        { code: 8, name: i18n.global.t('pages_receipt_024') }
      ],
      channelList: [
        { code: 'WECHAT', name: i18n.global.t('pages_receipt_027') },
        { code: 'ALIPAY', name: i18n.global.t('pages_receipt_028') }
      ],
      searchTypeList: [
        { code: '1', name: i18n.global.t('pages_receipt_008') },
        { code: '2', name: i18n.global.t('pages_receipt_013') }
      ],
      settlementList: [
        { code: 0, name: i18n.global.t('pages_receipt_025') },
        { code: 1, name: i18n.global.t('pages_receipt_026') }
      ]
    })

    const exportReceipts = async () => {
      state.downLoading = true
      await historyRef.value.exportReceipts()
      state.downLoading = false
    }

    return {
      todayRef,
      historyRef,
      exportReceipts,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#receiptOrders {
  .common-card-body {
    .order-tabs {
      padding: 0 24px;
    }
  }
}
</style>

